import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import 'dayjs/locale/tr';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.locale('tr');

export const utcToday = (): string => {
  //today yap - extend utc leri tamizle
  return dayjs().utc(true).startOf('day').format('YYYY-MM-DD');
};

export const utcNow = (): string => {
  //now yap
  return dayjs().utc(true).format('YYYY-MM-DDTHH:mm:ss');
};

export const fromNow = (date?: string): string => {
  return dayjs(date).utc(true).fromNow();
};

export const strSqlDateTime = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('YYYY-MM-DDTHH:mm:ss') ?? '';
};

export const strSqlDate = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('YYYY-MM-DD');
};

export const strHour = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('HH');
};

export const strTime = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('HH:mm');
};

export const strDay = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD');
};

export const strDayLong = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('dddd');
};

export const strDayCombine = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD dddd');
};

export const strDayMonthShort = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD MMM');
};

export const strDayMonth = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD MMMM');
};

export const strMonthYearShort = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('MMM YY');
};

export const strMonthYear = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('MMMM YYYY');
};

export const strDayMonthYearShort = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD MMM YYYY');
};

export const strDayMonthYear = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD MMMM YYYY');
};

export const strPointDayMonthYear = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD.MM.YYYY');
};

export const strPointDayMonthYearHour = (date?: string): string => {
  return dayjs(date)?.utc(true)?.format('DD.MM.YYYY HH:mm');
};

export const strFormat = (date?: string, format?: string): string => {
  return dayjs(date)?.utc(true)?.format(format);
};

export const strRangeWeek = (date?: string): string => {
  let isSameYear = year(startOf(date, 'week')) === year(endOf(date, 'week'));
  return `${isSameYear ? strDayMonthShort(startOf(date, 'week')) : strDayMonthYearShort(startOf(date, 'week'))} - ${strDayMonthYearShort(endOf(date, 'week'))}`;
};

export const strRangeWeekByMonth = (date?: string): string => {
  let isStartSameMonth = month(startOf(date, 'week')) === month(startOf(date, 'month'));
  let isEndSameMonth = month(endOf(date, 'week')) === month(endOf(date, 'month'));
  let startDate = isStartSameMonth ? startOf(date, 'week') : startOf(date, 'month');
  let endDate = isEndSameMonth ? endOf(date, 'week') : endOf(date, 'month');
  return `${strDay(startDate)}-${strFormat(endDate, 'DD MMM')}`;
};

export const isGreaterThan = (date?: string, date2?: string): boolean => {
  return dayjs(date).utc(true) > dayjs(date2).utc(true);
};

export const isSame = (date?: string, date2?: string): boolean => {
  return strSqlDate(date) === strSqlDate(date2);
};

export const isSameTime = (date?: string, date2?: string): boolean => {
  return strTime(date) === strTime(date2);
};

export const isSameDateTime = (date?: string, date2?: string): boolean => {
  return strSqlDateTime(date) === strSqlDateTime(date2);
};

export const day = (date?: string): number => {
  return dayjs(date)?.utc(true)?.day();
};

export const month = (date?: string): number => {
  return dayjs(date)?.utc(true)?.month();
};

export const year = (date?: string): number => {
  return dayjs(date)?.utc(true)?.year();
};

export const timeAsMinute = (date?: string): number => {
  return date ? hour(date) * 60 + minute(date) : 0;
};

export const minute = (date?: string): number => {
  return dayjs(date)?.utc(true)?.minute();
};

export const hour = (date?: string): number => {
  return dayjs(date)?.utc(true)?.hour();
};

export const daysInMonth = (date?: string): number => {
  return dayjs(date)?.utc(true)?.daysInMonth();
};

export const diffDay = (date?: string, date2?: string): number => {
  return dayjs(date)?.utc(true)?.diff(dayjs(date2)?.utc(true), 'day');
};

export const diffYear = (date?: string, date2?: string): number => {
  return dayjs(date)?.utc(true)?.diff(dayjs(date2)?.utc(true), 'year');
};

export const addMinute = (date?: string, value: number = 0): string => {
  return dayjs(date)?.utc(true)?.add(value, 'minute')?.format('YYYY-MM-DDTHH:mm:ss');
};

export const addDay = (date?: string, value: number = 0): string => {
  return dayjs(date)?.utc(true)?.add(value, 'day')?.format('YYYY-MM-DDTHH:mm:ss');
};

export const addWeek = (date?: string, value: number = 0): string => {
  return dayjs(date)?.utc(true)?.add(value, 'week')?.format('YYYY-MM-DDTHH:mm:ss');
};

export const addMonth = (date?: string, value: number = 0): string => {
  return dayjs(date)?.utc(true)?.add(value, 'month')?.format('YYYY-MM-DDTHH:mm:ss');
};

export const addYear = (date?: string, value: number = 0): string => {
  return dayjs(date)?.utc(true)?.add(value, 'year')?.format('YYYY-MM-DDTHH:mm:ss');
};

export const startOf = (date?: string, unit: dayjs.OpUnitType = 'day'): string => {
  return dayjs(date)?.utc(true)?.startOf(unit)?.format('YYYY-MM-DDTHH:mm:ss');
};

export const endOf = (date?: string, unit: dayjs.OpUnitType = 'day'): string => {
  return dayjs(date)?.utc(true)?.endOf(unit)?.format('YYYY-MM-DDTHH:mm:ss');
};