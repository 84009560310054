import { createSlice } from "@reduxjs/toolkit";
import { string } from "yup";

const initialState = {
  totalPageNumber: 0,
  apiKey: undefined,
  fixedPages: undefined,
  customPages: undefined,
  splitRange: undefined,
  password: undefined,
  modelId: undefined,
  isForShortDocuments: true,
  refresh: false,
  files: [],
  response: undefined,
  responseModels: []
};

export const liveInterfaceSlice = createSlice({
  name: "liveInterface",
  initialState,
  reducers: {
    setTotalPageNumber: (state, action) => {
      state.totalPageNumber = action.payload;
    },
    setApiKey: (state, action) => {
      state.apiKey = action.payload;
    },
    setFixedPages: (state, action) => {
      state.fixedPages = action.payload;
    },
    setCustomPages: (state, action) => {
      state.customPages = action.payload;
    },
    setSplitRange: (state, action) => {
      state.splitRange = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setModelId: (state, action) => {
      state.modelId = action.payload;
    },
    setIsForShortDocuments: (state, action) => {
      state.isForShortDocuments = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    refresh: (state) => {
        state.refresh = !state.refresh;
    },
    resetModel: (state) => {
        state.totalPageNumber = 0;
        state.fixedPages = undefined;
        state.customPages = undefined;
        state.password = undefined;
        state.modelId = undefined;
        state.isForShortDocuments = true;
        state.files = [];
        state.response = undefined;
        state.responseModels = []
    },
    setResponse: (state, action) => {
        state.response = action.payload.response;
        state.responseModels = action.payload.responseModels;
    },
    resetResponse: (state) => {
        state.response = undefined;
        state.responseModels = []
    },
  },
});

export const { setTotalPageNumber, setApiKey, setFixedPages, setCustomPages, setSplitRange ,setPassword, setModelId, setIsForShortDocuments, setFiles, refresh, resetModel, setResponse, resetResponse } = liveInterfaceSlice.actions;

export default liveInterfaceSlice.reducer;
