import { useLocation, useNavigate } from "react-router";
import * as Keys from "../../translations/keys";
import { ArrowLeft, Globe } from "../../components/icons";
import styles from "./styles/publicLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { shortEnLanguage, shortTrLanguage } from "../../common/constants";
import { Button } from "../../components/button/Button";
import { Typography } from "../../components/typography/Typography";
import { setLanguage } from "../../stores/settings/settingsSlice";
import { translate } from "../../utils/language";
import { Url_NotFound, Url_Redirect, Url_Sign_In } from "../../utils/routeHelper";
import { StateModel } from "../../models/store/StateModel";

export const PublicLanguageSelect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const language = useSelector(
    (state: StateModel) => state?.settings?.language
  );
  const dispatch = useDispatch();

  const handleLanguageClick = (lang: string) => {
    dispatch(setLanguage(lang));
  };

  return (
    <>
      {location.pathname.toLowerCase().trim() !==
        Url_Sign_In.toLowerCase().trim() && location.pathname.toLowerCase().trim() !==
        Url_NotFound.toLowerCase().trim() && location.pathname.toLowerCase().trim() !==
        Url_Redirect.toLowerCase().trim() && (
        <div className={styles.public__back}>
          <Button
            onClick={() => {
              navigate(Url_Sign_In);
            }}
            className={`mx-1 ${styles.public__back__button}`}
            startIcon={<ArrowLeft />}
            color="Dark-Blue"
            variant="text"
          >
            <Typography size="lg" label={translate(Keys.Back)} />
          </Button>
        </div>
      )}

      <div className={styles.public__language}>
        <Globe className="mx-1" />
        <Button
          onClick={() => {
            handleLanguageClick("en");
          }}
          className={`mx-1 ${styles.public__language__button}`}
          passive={language !== shortEnLanguage}
          variant="text"
        >
          {translate(Keys.EnLang)}
        </Button>
        <Button
          onClick={() => {
            handleLanguageClick("tr");
          }}
          className={`mx-1 ${styles.public__language__button}`}
          passive={language !== shortTrLanguage}
          variant="text"
        >
          {translate(Keys.TrLang)}
        </Button>
      </div>
    </>
  );
};
